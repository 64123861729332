<template>
  <div>
    <base-header class="pb-6">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-2 col-md-6">
          <stats-card
            title="vị trí TB"
            type="gradient-info"
            icon="ni ni-chart-bar-32"
          >
            <template slot="footer">
              <span
                :class="[
                  averagePositionDiff < 0 ? 'text-success' : '',
                  averagePositionDiff > 0 ? 'text-danger' : '',
                  'mr-2',
                ]"
              >
                <i v-if="averagePositionDiff < 0" class="fa fa-arrow-up"></i>
                <i v-if="averagePositionDiff > 0" class="fa fa-arrow-down"></i>
                {{
                  latestTracking && latestTracking.average_position !== null
                    ? latestTracking.average_position
                    : "N/A"
                }} </span
              ><br />
              <span
                v-if="
                  previousTracking && previousTracking.average_position !== null
                "
                class="text-nowrap"
                >vs {{ previousTracking.average_position }} (thay đổi
                {{
                  !isNaN(averagePositionDiff) ? averagePositionDiff : "N/A"
                }})</span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-xl-2 col-md-6">
          <stats-card title="Tăng" type="gradient-red" icon="ni ni-active-40">
            <template slot="footer">
              <span
                :class="[
                  rankUpDiff > 0 ? 'text-success' : '',
                  rankUpDiff < 0 ? 'text-danger' : '',
                  'mr-2',
                ]"
              >
                <i v-if="rankUpDiff > 0" class="fa fa-arrow-up"></i>
                <i v-if="rankUpDiff < 0" class="fa fa-arrow-down"></i>
                {{
                  latestTracking && latestTracking.rank_up_keywords !== null
                    ? latestTracking.rank_up_keywords
                    : "N/A"
                }} </span
              ><br />
              <span
                v-if="
                  previousTracking && previousTracking.rank_up_keywords !== null
                "
                class="text-nowrap"
                >vs {{ previousTracking.rank_up_keywords }} (thay đổi
                {{ rankUpDiff }})</span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-xl-2 col-md-6">
          <stats-card title="Top 3" type="gradient-orange" icon="ni ni-world-2">
            <template slot="footer">
              <span
                :class="[
                  top3Diff > 0 ? 'text-success' : '',
                  top3Diff < 0 ? 'text-danger' : '',
                  'mr-2',
                ]"
              >
                <i v-if="top3Diff > 0" class="fa fa-arrow-up"></i>
                <i v-if="top3Diff < 0" class="fa fa-arrow-down"></i>
                {{
                  latestTracking && latestTracking.top_3_keywords !== null
                    ? latestTracking.top_3_keywords
                    : "N/A"
                }} </span
              ><br />
              <span
                v-if="
                  previousTracking && previousTracking.top_3_keywords !== null
                "
                class="text-nowrap"
                >vs {{ previousTracking.top_3_keywords }} (thay đổi
                {{ top3Diff }})</span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-xl-2 col-md-6">
          <stats-card
            title="Top 10"
            type="gradient-green"
            icon="ni ni-chart-pie-35"
          >
            <template slot="footer">
              <span
                :class="[
                  top10Diff > 0 ? 'text-success' : '',
                  top10Diff < 0 ? 'text-danger' : '',
                  'mr-2',
                ]"
              >
                <i v-if="top10Diff > 0" class="fa fa-arrow-up"></i>
                <i v-if="top10Diff < 0" class="fa fa-arrow-down"></i>
                {{
                  latestTracking && latestTracking.top_10_keywords !== null
                    ? latestTracking.top_10_keywords
                    : "N/A"
                }} </span
              ><br />
              <span
                v-if="
                  previousTracking && previousTracking.top_10_keywords !== null
                "
                class="text-nowrap"
                >vs {{ previousTracking.top_10_keywords }} (thay đổi
                {{ top10Diff }})</span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-xl-2 col-md-6">
          <stats-card title="Top 30" type="gradient-purple" icon="ni ni-world">
            <template slot="footer">
              <span
                :class="[
                  top30Diff > 0 ? 'text-success' : '',
                  top30Diff < 0 ? 'text-danger' : '',
                  'mr-2',
                ]"
              >
                <i v-if="top30Diff > 0" class="fa fa-arrow-up"></i>
                <i v-if="top30Diff < 0" class="fa fa-arrow-down"></i>
                {{
                  latestTracking && latestTracking.top_30_keywords !== null
                    ? latestTracking.top_30_keywords
                    : "N/A"
                }} </span
              ><br />
              <span
                v-if="
                  previousTracking && previousTracking.top_30_keywords !== null
                "
                class="text-nowrap"
                >vs {{ previousTracking.top_30_keywords }} (thay đổi
                {{ top30Diff }})</span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-xl-2 col-md-6">
          <stats-card title="Top 100" type="gradient-teal" icon="ni ni-planet">
            <template slot="footer">
              <span
                :class="[
                  top100Diff > 0 ? 'text-success' : '',
                  top100Diff < 0 ? 'text-danger' : '',
                  'mr-2',
                ]"
              >
                <i v-if="top100Diff > 0" class="fa fa-arrow-up"></i>
                <i v-if="top100Diff < 0" class="fa fa-arrow-down"></i>
                {{
                  latestTracking && latestTracking.top_100_keywords !== null
                    ? latestTracking.top_100_keywords
                    : "N/A"
                }} </span
              ><br />
              <span
                v-if="
                  previousTracking && previousTracking.top_100_keywords !== null
                "
                class="text-nowrap"
                >vs {{ previousTracking.top_100_keywords }} (thay đổi
                {{ top100Diff }})</span
              >
            </template>
          </stats-card>
        </div>
      </div>

      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7"></div>
        <div class="col-lg-6 col-5 text-right">
          <base-button
            size="sm"
            type="primary"
            @click="showAddKeywordModal = true"
            ><i class="ni ni-fat-add"></i> Thêm từ khóa</base-button
          >
          <base-button size="sm" type="neutral"
            ><i class="ni ni ni-ui-04"></i> Bộ lọc</base-button
          >
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">Danh sách từ khóa</h3>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="listQuery.limit"
                placeholder="Per page"
                @change="handleFilter"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="listQuery.search"
                  prepend-icon="fas fa-search"
                  placeholder="Tìm kiếm..."
                  @keyup.enter.native="handleFilter"
                >
                </base-input>
              </div>
            </div>

            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              v-loading="listLoading"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-bind="{
                  label: 'Từ khóa',
                  minWidth: 200,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  <router-link
                    :to="{ name: 'ViewTerm', params: { id: scope.row.id } }"
                    >{{ scope.row.keyword }}</router-link
                  >
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Search Volume',
                  minWidth: 120,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{ scope.row.search_volume || "N/A" }}
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Thay đổi TH',
                  minWidth: 100,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  <span
                    :class="[
                      scope.row.rank_diff < 0 ? 'text-success' : '',
                      scope.row.rank_diff > 0 ? 'text-danger' : '',
                      'mr-2',
                    ]"
                  >
                    <i
                      v-if="scope.row.rank_diff < 0"
                      class="fa fa-arrow-up"
                    ></i>
                    <i
                      v-if="scope.row.rank_diff > 0"
                      class="fa fa-arrow-down"
                    ></i>
                    {{
                      scope.row.rank_diff &&
                      scope.row.rank_diff !== null &&
                      scope.row.rank_diff > 0
                        ? "-"
                        : ""
                    }}
                    {{
                      scope.row.rank_diff &&
                      scope.row.rank_diff !== null &&
                      scope.row.rank_diff < 0
                        ? "+"
                        : ""
                    }}
                    {{
                      scope.row.rank_diff && scope.row.rank_diff !== null
                        ? Math.abs(scope.row.rank_diff)
                        : "N/A"
                    }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'TH mới nhất',
                  minWidth: 100,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.current_rank && scope.row.current_rank !== null
                      ? scope.row.current_rank
                      : "N/A"
                  }}
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'TH cao nhất',
                  minWidth: 100,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.best_rank && scope.row.best_rank !== null
                      ? scope.row.best_rank
                      : "N/A"
                  }}
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'TH ban đầu',
                  minWidth: 100,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.first_rank && scope.row.first_rank !== null
                      ? scope.row.first_rank
                      : "N/A"
                  }}
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Hoạt động',
                  minWidth: 100,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  <base-switch
                    class="mr-1"
                    v-model="scope.row.is_active"
                    type="success"
                    @input="updateTerm(scope.row)"
                  ></base-switch>
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Cập nhật',
                  minWidth: 200,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{ scope.row.updated_at }}
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'URL tìm thấy',
                  minWidth: 160,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.tracking && scope.row.tracking.found_url
                      ? scope.row.tracking.found_url
                      : "Không tìm thấy"
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">Tổng cộng: {{ pagination.total }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :page-count="pagination.pageCount"
              :per-page="pagination.perPage"
              :total="pagination.total"
              @input="getTerms"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="showAddKeywordModal" size="lg" id="add-keyword-modal">
      <div class="container-fluid">
        <base-alert type="danger" v-if="errText !== ''">
          <strong>Lỗi!</strong> {{ errText }}
        </base-alert>

        <!-- Form groups used in grid -->
        <div class="row">
          <div class="col-md-12">
            <base-input
              label="Từ khóa(nhập nhiều bằng cách xuống dòng)"
              :errors="errors.get('keyword') ? [errors.get('keyword')] : []"
            >
              <textarea
                v-model="inputs.keyword"
                :class="[
                  errors.get('keyword') ? 'is-invalid' : '',
                  'form-control',
                ]"
                rows="10"
              ></textarea>
            </base-input>
          </div>
        </div>

        <base-button
          type="primary"
          native-type="submit"
          @click.prevent="addTerm"
          :loading="isLoading"
          >Thêm từ khóa</base-button
        >
      </div>
    </modal>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "@/components";
import Errors from "@/utils/error";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      projectId: null,
      tableData: [],
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0,
        pageCount: 1,
      },
      listQuery: {
        page: 1,
        limit: 100,
        search: undefined,
        sortBy: "created_at",
      },
      listLoading: false,
      urls: [],
      showAddKeywordModal: false,
      inputs: {
        keyword: "",
        location_name: "",
        language_name: "",
        se_domain: "",
        device: "",
        os: "",
        term_project_id: this.$route.params.id,
      },
      project: null,
      latestTracking: null,
      previousTracking: null,
      errors: new Errors(),
      errText: "",
      submitSuccess: false,
      isLoading: false,
    };
  },
  mounted() {
    this.projectId = this.$route.params.id;
    this.getProject();
    this.getTerms();
  },
  computed: {
    averagePositionDiff() {
      if (this.previousTracking) {
        return (
          parseFloat(this.latestTracking.average_position) -
          parseFloat(this.previousTracking.average_position)
        );
      }

      return 0;
    },
    rankUpDiff() {
      if (this.previousTracking) {
        return (
          parseFloat(this.latestTracking.rank_up_keywords) -
          parseFloat(this.previousTracking.rank_up_keywords)
        );
      }

      return 0;
    },
    top3Diff() {
      if (this.previousTracking) {
        return (
          parseFloat(this.latestTracking.top_3_keywords) -
          parseFloat(this.previousTracking.top_3_keywords)
        );
      }

      return 0;
    },
    top10Diff() {
      if (this.previousTracking) {
        return (
          parseFloat(this.latestTracking.top_10_keywords) -
          parseFloat(this.previousTracking.top_10_keywords)
        );
      }

      return 0;
    },
    top30Diff() {
      if (this.previousTracking) {
        return (
          parseFloat(this.latestTracking.top_30_keywords) -
          parseFloat(this.previousTracking.top_30_keywords)
        );
      }

      return 0;
    },
    top100Diff() {
      if (this.previousTracking) {
        return (
          parseFloat(this.latestTracking.top_100_keywords) -
          parseFloat(this.previousTracking.top_100_keywords)
        );
      }

      return 0;
    },
  },
  methods: {
    async getProject() {
      const data = await this.$store.dispatch("term/fetchProject", {
        id: this.projectId,
      });
      this.project = data;
      this.latestTracking = data.tracking;
      this.previousTracking = data.previous_tracking;

      this.inputs.location_name = data.location_name;
      this.inputs.language_name = data.language_name;
      this.inputs.se_domain = data.se_domain;
      this.inputs.device = data.device;
      this.inputs.os = data.os;
    },
    async getTerms(page = 1) {
      this.listLoading = true;

      let self = this;
      self.listQuery.page = page;

      try {
        const { data, pagination } = await this.$store.dispatch(
          "term/fetchTermByProject",
          { id: self.projectId, query: self.listQuery }
        );
        this.tableData = data;
        this.pagination.total = pagination.total;
        this.pagination.currentPage = pagination.current_page;
        this.pagination.perPage = pagination.per_page;
        this.pagination.pageCount = pagination.total_pages;
        this.listQuery.page = pagination.current_page;
        this.listLoading = false;
      } catch (e) {
        console.log(e);
      }

      this.$preloaders.close();
    },
    async addTerm() {
      let self = this;
      this.errors = new Errors();
      this.errText = "";
      this.isLoading = true;

      try {
        const response = await this.$store.dispatch(
          "term/addTerm",
          self.inputs
        );

        this.inputs.keyword = "";
        this.showAddKeywordModal = false;
        this.$notify({
          message: response.success
            ? "Thêm từ khóa thành công! Danh sách sẽ được cập nhật khi dữ liệu của từ khóa được tải xong."
            : "Thêm từ khóa không thành công!",
          timeout: 5000,
          icon: "ni ni-bell-55",
          type: response.success ? "success" : "danger",
          verticalAlign: "top",
          horizontalAlign: "right",
        });
        this.getTerms();
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        console.log(err.response);
        if (err.response.status === 422) {
          this.errors.record(err.response.data);
        }

        if (err.response.status === 503) {
          this.errText = "Thêm từ khóa không thành công xin vui lòng thử lại!";
        } else {
          this.errText = "Thông tin nhập vào không đúng, xin thử lại!";
        }

        this.isLoading = false;
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getTerms();
    },
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    async updateTerm(row) {
      let self = this;
      this.formLoading = true;
      this.errors = new Errors();
      this.errText = "";

      try {
        const response = await this.$store.dispatch("term/updateTerm", row);

        this.showDetailModal = false;
        this.$notify({
          message: response.status
            ? "Cập nhật trạng thái hoạt động thành công!"
            : "Cập nhật thông tin không thành công!",
          timeout: 2000,
          icon: "ni ni-bell-55",
          type: response.status ? "success" : "danger",
          verticalAlign: "bottom",
          horizontalAlign: "right",
        });
      } catch (err) {
        if (err.response.status === 422) {
          self.errors.record(err.response.data);
        }
      }
      this.formLoading = false;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

div.el-table tbody td {
  padding: 0.5rem 1rem;
}

div.el-table thead th {
  padding: 1rem;
}
</style>
