import { render, staticRenderFns } from "./ViewProject.vue?vue&type=template&id=60a6e55d&"
import script from "./ViewProject.vue?vue&type=script&lang=js&"
export * from "./ViewProject.vue?vue&type=script&lang=js&"
import style0 from "./ViewProject.vue?vue&type=style&index=0&id=60a6e55d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports